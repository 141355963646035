<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-chart-card
          :data="emailsSubscriptionChart.data"
          :options="emailsSubscriptionChart.options"
          :responsive-options="emailsSubscriptionChart.responsiveOptions"
          color="info"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Total de vendas
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Total líquido recebido nos últimos meses
          </p>
        </base-material-chart-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-chart-card
          :data="dataCompletedTasksChart.data"
          :options="dataCompletedTasksChart.options"
          hover-reveal
          color="info"
          type="Line"
        >
          <h3 class="card-title font-weight-light mt-2 ml-2">
            Quantidade de pedidos
          </h3>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Total de pedidos por mês
          </p>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <base-material-stats-card
          color="accent"
          icon="mdi-account-multiple-minus"
          title="Inadimplentes"
          :value="inadimplentes"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <base-material-stats-card
          color="accent"
          icon="mdi-poll"
          title="Pedidos (A prazo)"
          :value="pedidosBoleto"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <base-material-stats-card
          color="accent"
          icon="mdi-poll"
          title="Pedidos (A vista)"
          :value="pedidosCheckout"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="info"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Pedidos
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Últimos 10 pedidos realizados no portal
            </div>
          </template>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="primary--text">
                    ID
                  </th>
                  <th class="primary--text">
                    Revendedora
                  </th>
                  <th class="primary--text">
                    Forma de Pagamento
                  </th>
                  <th class="primary--text">
                    Total
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="pedido in pedidos"
                  :key="pedido.id"
                >
                  <td>
                    <router-link to="">
                      {{ pedido.id }}
                    </router-link>
                  </td>
                  <td>{{ pedido.cliente.nome }}</td>
                  <td>{{ pedido.tipoPagamento.nome }}</td>
                  <td>{{ pedido.totalLiquido | currency }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="info"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Revendedoras
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Últimas 10 revendedoras da página de captura
            </div>
          </template>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="primary--text">
                    ID
                  </th>
                  <th class="primary--text">
                    Nome
                  </th>
                  <th class="primary--text">
                    Telefone
                  </th>
                  <th class="primary--text">
                    CPF
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="cliente in clientes"
                  :key="cliente.id"
                >
                  <td>
                    <router-link to="">
                      {{ cliente.id }}
                    </router-link>
                  </td>
                  <td>
                    {{ cliente.nome }} -
                    <strong>{{ cliente.codigoStyllus }}</strong>
                  </td>
                  <td>
                    {{ cliente.contatoSMS }}
                  </td>
                  <td>
                    {{ cliente.cpf }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        inadimplentes: '0',
        pedidosBoleto: '0',
        pedidosCheckout: '0',
        pedidosCaptura: '0',
        pedidos: [],
        clientes: [],
        dailySalesChart: {
          data: {
            labels: [],
            series: [],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dataCompletedTasksChart: {
          data: {
            labels: [],
            series: [],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 2000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        emailsSubscriptionChart: {
          data: {
            labels: [],
            series: [],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 1000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
      }
    },

    async mounted () {
      if (this.$store.getters.user.perfilId === 25) {
        this.$router.push({ name: 'Dashboard do Promotor' })
      }
      const response = await this.$http.get('dashboard')
      if (response.data.novasRevendedoras && response.data.novasRevendedoras.length > 0) {
        this.dailySalesChart.data.labels = response.data.novasRevendedoras.map(item => {
          return new Date(item.data).toLocaleString('pt-br', { weekday: 'short' }).toUpperCase()
        })
        this.dailySalesChart.data.series.push(response.data.novasRevendedoras.map(item => {
          return item.total
        }))
      }
      if (response.data.totalPedidos && response.data.totalPedidos.length > 0) {
        this.dataCompletedTasksChart.data.labels = response.data.totalPedidos.map(item => {
          return new Date(item.data).toLocaleString('pt-br', { month: 'short' }).toUpperCase()
        })
        this.dataCompletedTasksChart.data.series.push(response.data.totalPedidos.map(item => {
          return item.total
        }))
      }
      if (response.data.totalPedidos && response.data.totalPedidos.length > 0) {
        this.emailsSubscriptionChart.data.labels = response.data.totalPedidos.map(item => {
          return new Date(item.data).toLocaleString('pt-br', { month: 'short' }).toUpperCase()
        })
        this.emailsSubscriptionChart.data.series.push(response.data.totalPedidos.map(item => {
          return item.total
        }))
      }
      this.inadimplentes = response.data.inadimplentes.toString()
      this.pedidosBoleto = response.data.pedidosBoleto.toString()
      this.pedidosCheckout = response.data.pedidosCheckout.toString()
      this.pedidosCaptura = response.data.pedidosCaptura.toString()
      this.pedidos = response.data.pedidos
      this.clientes = response.data.clientes
    },

    methods: {

    },
  }
</script>
